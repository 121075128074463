import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import * as Styles from '@config/styles';

const _View = () => {
  return (
    <View style={Styles.centeredView.container}>
      <Text>Initial Setup</Text>
      <StatusBar style="auto" />
    </View>
  );
}

export default _View;