import React, { useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import _404 from '@views/_404';
import Welcome from '@views/Welcome';
import InitialSetup from '@views/InitialSetup';
import Dashboard from '@views/Dashboard';
import { Routes } from '@config/index';

const Stack = createNativeStackNavigator();

function App() {
  return (
    <NavigationContainer linking={Routes}>
      <Stack.Navigator initialRouteName="Dashboard" screenOptions={{ headerShown: false }}>
        <Stack.Screen name="Dashboard" component={Dashboard} />
        <Stack.Screen name="InitialSetup" component={InitialSetup} />

        <Stack.Screen name="NotFound" component={_404} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default App;
