import { NavigationContainer } from '@react-navigation/native';

const config = {
  screens: {
    // initialRouteName: 'Dashboard',
    // Dashboard: 'feed/:sort',
    Dashboard: {
      path: ''
    },
    InitialSetup: 'setup',
    NotFound: '*'
  },
};

const linking = {
  prefixes: [
    'igua://', 'https://analytics.iugu.com'
  ],
  config
};

export default linking;